import { SubmitHandler, useForm, Validate } from 'react-hook-form'
import { CleanValueIcon } from '../components/CleanValueIcon'
import Header from '../components/Header'
import uploadIcon from '../images/createNFT/uploadIcon.png'
import uploadErrorIcon from '../images/createNFT/uploadErrorIcon.png'
import ethIcon from '../images/createNFT/ethIcon.png'
import polygonIcon from '../images/createNFT/polygonIcon.png'
import solanaIcon from '../images/createNFT/solanaIcon.png'
import klaythIcon from '../images/createNFT/klaythIcon.png'
import { useState } from 'react'
import { ErrorMessage } from '../components/ErrorMessage'
import Toast from '../components/toast'
import { toast } from 'react-toastify'
import UploadPreview from '../components/UploadPreview'
import Modal from '../components/Popup'
type CreateFormType = {
  fullName: string
  email: string
  description: string
  dealType: string
  anonOrPublic: string
  passKYC: string
  projectStatus: string
  partners: string
  docsendLink: string
  projectLink: string
  additionalComments: string
  projectPicture: FileList
}

const CreateNFT: React.FC = () => {
  const [descriptionLength, setDescriptionLength] = useState(0)
  const blockchainSelectList = [
    { icon: ethIcon, name: 'Ethereum' },
    { icon: polygonIcon, name: 'Polygon' },
    { icon: solanaIcon, name: 'Solana' },
    { icon: klaythIcon, name: 'Klayth' },
  ]
  const [currentBlockchain, setCurrentBlockchain] = useState({
    icon: ethIcon,
    name: 'Ethereum',
  })
  const [blockchainListVisible, setBlockchainListVisible] = useState(false)
  const handleSelectBlockchain = (selected: {
    icon: ImageData
    name: string
  }) => {
    setCurrentBlockchain(selected)
    setBlockchainListVisible(false)
  }
  const {
    register,
    handleSubmit,
    reset,
    resetField,
    watch,
    getValues,
    formState: { errors },
  } = useForm<CreateFormType>({
    defaultValues: { dealType: 'VC', anonOrPublic: 'Anon', passKYC: 'Yes' },
  })
  // ProjectPicture Field URL
  const [projectPictureUrl, setProjectPictureUrl] = useState<string | null>(
    null,
  )
  const [previewModalVisible, setPreviewModalVisible] = useState(false)
  // Preview Modal Image URL
  const [previewModalUrl, setPreviewModalUrl] = useState<string>('')
  const projectPictureeUploadChange = () => {
    const file = getValues('projectPicture')?.[0]
    file && setProjectPictureUrl(window.URL.createObjectURL(file) ?? null)
  }

  const onSubmit: SubmitHandler<CreateFormType> = data => {
    console.log('🚀 ~ data', data)
    toast.success('Successfully Created NFT')
    setProjectPictureUrl(null)
    reset()
  }

  return (
    <div
      className="bg-[#F3F7FA]"
      onClick={() => setBlockchainListVisible(false)}
    >
      <Header />
      <Toast />
      <div className="w-full pt-20 bg-[url('../images/createNFT/bg.png')] bg-no-repeat bg-top bg-cover text-sm overflow-hidden sm:pt-[57px] md:px-16 sm:px-4">
        <div className="text-[34px] font-boldText font-bold mt-[50px] text-center leading-[52px] sm:mt-[42px] sm:text-[32px] sm:leading-[36px]">
          Organization Application Form
        </div>
        <div className="mt-2 text-center text-[18px] sm:mt-[23px] sm:text-[14px] sm:text-[#333] sm:leading-[22px] sm:px-[28px]">
          Apply to launch on CTIO below so we can begin reviewing your
          application.
        </div>
        <form
          className="mb-[102px] mt-[47px] mx-auto max-w-[576px] bg-[rgba(254,254,254,0.3)] rounded-2xl px-[60px] pt-[42px] pb-[77px] sm:mt-4 sm:px-0 sm:pt-[42px] sm:pb-0"
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* General Infrormation */}
          <div>
            <div className="text-[24px] leading-[26px] after:content-['*'] after:w-1 after:h-4 after:text-red-500 sm:leading-[36px]">
              General Infrormation
            </div>
            <div className="mt-8">
              <div className="relative">
                <input
                  {...register('fullName', {
                    required: 'Please enter your fullName.',
                  })}
                  className={`h-[50px] w-full bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] outline-none p-4 text-[#828282] text-sm placeholder:text-[#828282] rounded border border-solid  caret-[#1E94FC] focus:shadow-[0_6px_18px_4px_#C0E2FF] hover:shadow-[0_6px_18px_4px_#C0E2FF] transition-all duration-300 ${
                    errors.fullName
                      ? 'border-red-500 focus:shadow-none hover:shadow-none'
                      : 'border-transparent focus:border-[#1E94FC] hover:border-[#1E94FC]'
                  }`}
                  placeholder="Full Name (person of contact)"
                />
                {watch('fullName') && (
                  <CleanValueIcon onClick={() => resetField('fullName')} />
                )}
              </div>
              {errors.fullName && (
                <ErrorMessage message={errors.fullName.message} />
              )}
            </div>
            <div className="mt-8">
              <div className="relative">
                <input
                  {...register('email', {
                    required: 'Please enter your email address.',
                  })}
                  className={`h-[50px] w-full bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] outline-none p-4 text-[#828282] text-sm placeholder:text-[#828282] rounded border border-solid  caret-[#1E94FC] focus:shadow-[0_6px_18px_4px_#C0E2FF] hover:shadow-[0_6px_18px_4px_#C0E2FF] transition-all duration-300 ${
                    errors.email
                      ? 'border-red-500 focus:shadow-none hover:shadow-none'
                      : 'border-transparent focus:border-[#1E94FC] hover:border-[#1E94FC]'
                  }`}
                  placeholder="Email address (person of contact)"
                />
                {watch('email') && (
                  <CleanValueIcon onClick={() => resetField('email')} />
                )}
              </div>
              {errors.email && <ErrorMessage message={errors.email.message} />}
            </div>
            <div className="mt-8">
              <div className="relative">
                <textarea
                  {...register('description', {
                    required: 'Please enter your description.',
                    onChange: e => setDescriptionLength(e.target.value.length),
                  })}
                  className={`w-full min-h-[172px] resize-none rounded outline-none p-4 text-[#828282] placeholder:text-[#828282] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] border border-solid caret-[#1E94FC] focus:shadow-[0_6px_18px_4px_#C0E2FF] pb-8 hover:shadow-[0_6px_18px_4px_#C0E2FF] transition-all duration-300 ${
                    errors.description
                      ? 'border-red-500 focus:shadow-none hover:shadow-none'
                      : 'border-transparent focus:border-[#1E94FC] hover:border-[#1E94FC]'
                  }`}
                  maxLength={200}
                  placeholder="Project Description"
                />
                {watch('description') && (
                  <CleanValueIcon
                    className="!right-[75px] top-[87%]"
                    onClick={() => resetField('description')}
                  />
                )}
                <div className="text-[#BDBDBD] text-[14px] leading-[14px] absolute bottom-4 right-[18px]">
                  <span className="text-[#1E94FC]">{descriptionLength}</span>
                  /200
                </div>
              </div>
              {errors.description && (
                <ErrorMessage message={errors.description.message} />
              )}
            </div>
          </div>

          {/* What type of deal are you applying for? */}
          <div className="mt-[72px]">
            <div className="text-[24px] leading-[26px] after:content-['*'] after:w-1 after:h-4 after:text-red-500 sm:leading-[36px]">
              What type of deal are you applying for?
            </div>
            <div className="mt-8">
              <div className="flex">
                {['VC', 'IDO', 'Both'].map(x => (
                  <label
                    {...register('dealType')}
                    htmlFor={x}
                    className={`w-[82px] h-[37px] rounded-[30px] shadow-[0px_16px_24px_1px_rgba(0,0,0,0.04)] text-[14px] leading-4 flex justify-center items-center mr-14 cursor-pointer sm:mr-[30px] whitespace-nowrap last:mr-0 ${
                      watch('dealType') !== x
                        ? 'text-[#333] bg-white'
                        : 'text-white bg-[#1E94FC]'
                    }`}
                  >
                    <input
                      {...register('dealType', {
                        required: 'Please select deal type.',
                      })}
                      type="radio"
                      id={x}
                      value={x}
                      className="hidden"
                    />
                    {x}
                  </label>
                ))}
              </div>
              {errors.dealType && (
                <ErrorMessage message={errors.dealType.message} />
              )}
            </div>
          </div>

          {/* Blockchain */}
          <div className="mt-[72px]">
            <div className="text-[24px] leading-[26px] sm:leading-[36px]">
              Blockchain
            </div>
            <div className="relative mt-8 select-none">
              <div
                className="h-16 bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] rounded p-4 flex items-center justify-between cursor-pointer hover:shadow-[0_6px_18px_4px_#C0E2FF] transition-all duration-300 border border-solid border-transparent hover:border-[#1E94FC]"
                onClick={e => {
                  e.stopPropagation()
                  setBlockchainListVisible(!blockchainListVisible)
                }}
              >
                <div className="flex items-center">
                  <img
                    src={currentBlockchain.icon}
                    alt="blockchain"
                    className="w-8 h-8 rounded-full"
                  />
                  <span className="text-[16px] leading-[22px] ml-[10px]">
                    {currentBlockchain.name}
                  </span>
                </div>
                <svg
                  className="w-4 h-4 text-[#B0B0B0]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              <div
                className={`w-full bg-white absolute shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] rounded mt-2 overflow-hidden transition-all ${
                  blockchainListVisible ? 'h-[188px]' : 'h-0'
                }`}
              >
                {blockchainSelectList.map(x => (
                  <div
                    className="flex items-center px-4 last:mb-[6px] hover:bg-[#F0F8FF] py-[6px] cursor-pointer first:mt-[6px]"
                    onClick={e => {
                      e.stopPropagation()
                      handleSelectBlockchain(x)
                    }}
                  >
                    <img
                      src={x.icon}
                      alt="blockchain"
                      className="w-8 h-8 rounded-full"
                    />
                    <span className="text-[16px] leading-[22px] ml-[10px]">
                      {x.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Is Your Team Anon or Public? */}
          <div className="mt-[72px]">
            <div className="text-[24px] leading-[26px] after:content-['*'] after:w-1 after:h-4 after:text-red-500 sm:leading-[36px]">
              Is Your Team Anon or Public?
            </div>
            <div className="mt-8">
              <div className="flex">
                {['Anon', 'Fully Public', 'Mixed'].map(x => (
                  <label
                    {...register('anonOrPublic')}
                    htmlFor={x}
                    className={`h-[37px] rounded-[30px] shadow-[0px_16px_24px_1px_rgba(0,0,0,0.04)] text-[14px] leading-4 flex justify-center items-center mr-14 cursor-pointer px-6 py-3 sm:mr-[30px] whitespace-nowrap last:mr-0 ${
                      watch('anonOrPublic') !== x
                        ? 'text-[#333] bg-white'
                        : 'text-white bg-[#1E94FC]'
                    }`}
                  >
                    <input
                      {...register('anonOrPublic', {
                        required: 'Please select Anon or Public.',
                      })}
                      type="radio"
                      id={x}
                      value={x}
                      className="hidden"
                    />
                    {x}
                  </label>
                ))}
              </div>
              {errors.dealType && (
                <ErrorMessage message={errors.dealType.message} />
              )}
            </div>
          </div>

          {/* Are the shareholders ready to pass KYC? */}
          <div className="mt-[72px]">
            <div className="text-[24px] leading-[26px] after:content-['*'] after:w-1 after:h-4 after:text-red-500 sm:leading-[36px]">
              Are the shareholders ready to pass KYC?
            </div>
            <div className="mt-8">
              <div className="flex">
                {['Yes', 'No'].map(x => (
                  <label
                    {...register('passKYC')}
                    htmlFor={x}
                    className={`h-[37px] rounded-[30px] shadow-[0px_16px_24px_1px_rgba(0,0,0,0.04)] text-[14px] leading-4 flex justify-center items-center mr-14 cursor-pointer px-6 py-3 sm:mr-[30px] whitespace-nowrap last:mr-0 ${
                      watch('passKYC') !== x
                        ? 'text-[#333] bg-white'
                        : 'text-white bg-[#1E94FC]'
                    }`}
                  >
                    <input
                      {...register('passKYC', {
                        required: 'Please select Anon or Public.',
                      })}
                      type="radio"
                      id={x}
                      value={x}
                      className="hidden"
                    />
                    {x}
                  </label>
                ))}
              </div>
              {errors.dealType && (
                <ErrorMessage message={errors.dealType.message} />
              )}
            </div>
          </div>

          {/* Project Status (briefly) */}
          <div className="mt-[72px]">
            <div className="text-[24px] leading-[26px] after:content-['*'] after:w-1 after:h-4 after:text-red-500 sm:leading-[36px]">
              Project Status (briefly)
            </div>
            <div className="mt-[10px] text-[#828282]">
              When do you expect working product/mainnet? Feel free share your
              roadmap
            </div>
            <div className="mt-8">
              <div className="relative">
                <input
                  {...register('projectStatus', {
                    required: 'Please enter project status.',
                  })}
                  className={`h-[50px] w-full bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] outline-none p-4 text-[#828282] text-sm placeholder:text-[#828282] rounded border border-solid caret-[#1E94FC] focus:shadow-[0_6px_18px_4px_#C0E2FF] hover:shadow-[0_6px_18px_4px_#C0E2FF] transition-all duration-300 ${
                    errors.projectStatus
                      ? 'border-red-500 focus:shadow-none hover:shadow-none'
                      : 'border-transparent focus:border-[#1E94FC] hover:border-[#1E94FC]'
                  }`}
                  placeholder="Contents"
                />
                {watch('projectStatus') && (
                  <CleanValueIcon onClick={() => resetField('projectStatus')} />
                )}
              </div>
              {errors.projectStatus && (
                <ErrorMessage message={errors.projectStatus.message} />
              )}
            </div>
          </div>

          {/* Partners */}
          <div className="mt-[72px]">
            <div className="text-[24px] leading-[26px] sm:leading-[36px]">
              Partners
            </div>
            <div className="mt-[10px] text-[#828282]">
              Provide a list KOL's, media partners, big brands etc if you have
              any.
            </div>
            <div className="mt-8">
              <div className="relative">
                <input
                  {...register('partners')}
                  className="h-[50px] w-full bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] outline-none p-4 text-[#828282] text-sm placeholder:text-[#828282] rounded border border-solid caret-[#1E94FC] focus:shadow-[0_6px_18px_4px_#C0E2FF] hover:shadow-[0_6px_18px_4px_#C0E2FF] transition-all duration-300 border-transparent focus:border-[#1E94FC] hover:border-[#1E94FC]"
                  placeholder="Contents"
                />
                {watch('partners') && (
                  <CleanValueIcon onClick={() => resetField('partners')} />
                )}
              </div>
            </div>
          </div>

          {/* Link to Litepaper or Whitepaper */}
          <div className="mt-[72px]">
            <div className="text-[24px] leading-[26px] after:content-['*'] after:w-1 after:h-4 after:text-red-500 sm:leading-[36px]">
              Link to Litepaper or Whitepaper
            </div>
            <div className="mt-[10px] text-[#828282]">
              Please add Google Drive or Docsend links
            </div>
            <div className="mt-8">
              <div className="relative">
                <input
                  {...register('docsendLink', {
                    required: 'Please enter litepaper or whitepaper link.',
                  })}
                  className={`h-[50px] w-full bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] outline-none p-4 text-[#828282] text-sm placeholder:text-[#828282] rounded border border-solid caret-[#1E94FC] focus:shadow-[0_6px_18px_4px_#C0E2FF] hover:shadow-[0_6px_18px_4px_#C0E2FF] transition-all duration-300 ${
                    errors.docsendLink
                      ? 'border-red-500 focus:shadow-none hover:shadow-none'
                      : 'border-transparent focus:border-[#1E94FC] hover:border-[#1E94FC]'
                  }`}
                  placeholder="Link"
                />
                {watch('docsendLink') && (
                  <CleanValueIcon onClick={() => resetField('docsendLink')} />
                )}
              </div>
              {errors.docsendLink && (
                <ErrorMessage message={errors.docsendLink.message} />
              )}
            </div>
          </div>

          {/* Project Link */}
          <div className="mt-[72px]">
            <div className="text-[24px] leading-[26px] after:content-['*'] after:w-1 after:h-4 after:text-red-500 sm:leading-[36px]">
              Project Link
            </div>
            <div className="mt-[10px] text-[#828282]">
              Feel free to include an additional demo links to your product (if
              any)
            </div>
            <div className="mt-8">
              <div className="relative">
                <input
                  {...register('projectLink', {
                    required: 'Please enter project link.',
                  })}
                  className={`h-[50px] w-full bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] outline-none p-4 text-[#828282] text-sm placeholder:text-[#828282] rounded border border-solid caret-[#1E94FC] focus:shadow-[0_6px_18px_4px_#C0E2FF] hover:shadow-[0_6px_18px_4px_#C0E2FF] transition-all duration-300 ${
                    errors.projectLink
                      ? 'border-red-500 focus:shadow-none hover:shadow-none'
                      : 'border-transparent focus:border-[#1E94FC] hover:border-[#1E94FC]'
                  }`}
                  placeholder="Link"
                />
                {watch('projectLink') && (
                  <CleanValueIcon onClick={() => resetField('projectLink')} />
                )}
              </div>
              {errors.projectLink && (
                <ErrorMessage message={errors.projectLink.message} />
              )}
            </div>
          </div>

          {/* Additional Comments */}
          <div className="mt-[72px]">
            <div className="text-[24px] leading-[26px] sm:leading-[36px]">
              Additional Comments
            </div>
            <div className="mt-[10px] text-[#828282]">
              Feel free to include an additional demo links to your product (if
              any)
            </div>
            <div className="mt-8">
              <div className="relative">
                <input
                  {...register('additionalComments')}
                  className={`h-[50px] w-full bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] outline-none p-4 text-[#828282] text-sm placeholder:text-[#828282] rounded border border-solid caret-[#1E94FC] focus:shadow-[0_6px_18px_4px_#C0E2FF] hover:shadow-[0_6px_18px_4px_#C0E2FF] transition-all duration-300 ${
                    errors.additionalComments
                      ? 'border-red-500 focus:shadow-none hover:shadow-none'
                      : 'border-transparent focus:border-[#1E94FC] hover:border-[#1E94FC]'
                  }`}
                  placeholder="Comments"
                />
                {watch('additionalComments') && (
                  <CleanValueIcon
                    onClick={() => resetField('additionalComments')}
                  />
                )}
              </div>
            </div>
          </div>

          {/* Project picture */}
          <div className="mt-[72px]">
            <div className="text-[24px] leading-[26px] sm:leading-[36px]">
              Project picture
            </div>
            <div className="mt-[10px] text-[#828282]">
              The picture will be used as the cover of the platform
            </div>
            <input
              {...register('projectPicture', {
                required: 'Please upload project picture.',
                onChange: projectPictureeUploadChange,
              })}
              id="projectPicture"
              type="file"
              className="hidden"
            />
            {projectPictureUrl ? (
              <UploadPreview
                className="w-[155px] h-[110px]"
                previewImageUrl={projectPictureUrl}
                previewBtnCallback={e => {
                  e.stopPropagation()
                  setPreviewModalVisible(true)
                  setPreviewModalUrl(projectPictureUrl)
                }}
                deleteBtnCallback={e => {
                  e.stopPropagation()
                  setProjectPictureUrl(null)
                  resetField('projectPicture')
                }}
              />
            ) : (
              <label
                htmlFor="projectPicture"
                className="w-[155px] h-[110px] mt-8 bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] rounded cursor-pointer flex border border-solid hover:shadow-[0_6px_18px_4px_#C0E2FF] transition-all duration-300 border-transparent hover:border-[#1E94FC]"
              >
                <img
                  src={uploadIcon}
                  alt="uploadIcon"
                  className="w-12 h-12 m-auto"
                />
              </label>
            )}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="bg-[#1E94FC] rounded w-full h-[62px] text-white shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] text-[18px] leading-[30px] mt-[72px]"
          >
            Create
          </button>
        </form>
      </div>
      <Modal
        visible={previewModalVisible}
        onOk={() => setPreviewModalVisible(false)}
        onCancel={() => setPreviewModalVisible(false)}
      >
        <img
          src={previewModalUrl}
          alt="previewModalUrl"
          className="object-cover w-full h-full rounded-2xl"
        />
      </Modal>
    </div>
  )
}

export default CreateNFT
